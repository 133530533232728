export const PaymentEventStatus = [
  { title: 'Pending', value: 'pending' },
  { title: 'Completed', value: 'completed' },
  { title: 'Failed', value: 'failed' },
  { title: 'Expired', value: 'expired' },
  { title: '3DSecure started', value: 'enrolled_3ds' },
  { title: '3DSecure verified', value: 'verified_3ds' },
  { title: 'Refunded', value: 'refunded' },
  { title: 'Partially Refunded', value: 'partially_refunded' },
];

export const PaymentEventStatusFilter = [
  { title: 'Pending', value: 'pending' },
  { title: 'Completed', value: 'completed' },
  { title: 'Failed', value: 'failed' },
  { title: 'Expired', value: 'expired' },
  { title: 'Refunded', value: 'refunded' },
  { title: 'Partially Refunded', value: 'partially_refunded' },
];

export const PaymentEventStatusEnum  = {
  PENDING: 'pending',
  COMPLETED: 'completed',
  FAILED: 'failed',
  EXPIRED: 'expired',
  ENROLLED_3DS: 'enrolled_3ds',
  VERIFIED_3DS: 'verified_3ds',
  REFUNDED: 'refunded',
  PARTIALLY_REFUNDED: 'partially_refunded',
};
