<template>
  <div id="payment-events-list">
    <v-card>
      <v-card-title v-if="!props.subscriptionId">Payments list</v-card-title>
      <v-row class="px-2 ma-0">
        <!-- status filter -->
        <v-col cols="12" sm="3">
          <v-select
            class="payment-event-status-filter"
            v-model="statusFilter"
            placeholder="Select Status"
            :items="PaymentEventStatusFilter"
            item-text="title"
            item-value="value"
            outlined
            dense
            clearable
            hide-details
          ></v-select>
        </v-col>
        <v-col v-if="isAdmin && !props.subscriptionId" cols="12" sm="3">
          <v-autocomplete
            class="payment-event-company-filter"
            v-model="companyFilter"
            :items="companies"
            item-value="id"
            item-text="name"
            dense
            filled
            outlined
            clearable
            hide-details
            label="Select company"
          ></v-autocomplete>
        </v-col>
        <v-col v-if="!props.subscriptionId" cols="12" sm="3">
          <v-select
            class="payment-event-card-type-filter"
            v-model="cardTypeFilter"
            placeholder="Select Card type"
            :items="cardTypeOptions"
            item-text="title"
            item-value="value"
            outlined
            dense
            clearable
            hide-details
          ></v-select>
        </v-col>
        <v-col cols="12" sm="3">
          <v-dialog ref="dialog" v-model="paymentEventDateModal" :return-value.sync="paymentEventDateFilter" width="290px">
            <template #activator="{ on, attrs }">
              <v-text-field
                v-model="paymentEventDateFilter"
                class="payment-event-date-filter"
                label="Select Payment date"
                outlined
                dense
                clearable
                hide-details
                v-bind="attrs"
                v-on="on"
              ></v-text-field>
            </template>
            <v-date-picker v-model="paymentEventDateFilter" class="payment-event-date-picker" scrollable range>
              <v-row>
                <v-col cols="12">
                  <v-select
                    placeholder="Select presets"
                    :items="presetsForPaymentEventDatePicker"
                    item-text="title"
                    item-value="value"
                    outlined
                    dense
                    clearable
                    hide-details
                    @change="selectedPaymentEventPresetDate"
                  ></v-select>
                </v-col>
              </v-row>
              <v-row style="text-align: end">
                <v-col cols="12">
                  <v-spacer></v-spacer>
                  <v-btn text color="primary" @click="$refs.dialog.save((paymentEventDateFilter = null))"> Cancel </v-btn>
                  <v-btn text color="primary" @click="$refs.dialog.save(paymentEventDateFilter)"> OK </v-btn>
                </v-col>
              </v-row>
            </v-date-picker>
          </v-dialog>
        </v-col>

        <v-col cols="12" sm="6">
          <v-text-field
            v-model="searchQuery"
            placeholder="Search"
            outlined
            hide-details
            dense
            class="chargeback-event-search-query user-search mb-4"
          >
          </v-text-field>
        </v-col>
      </v-row>

      <v-data-table
        :options.sync="options"
        :headers="tableColumns"
        :items="paymentEventListTable"
        :server-items-length="totalPaymentEventListTable"
        :item-class="paymentEventItemClass"
        :loading="loading"
        :footer-props="{ 'items-per-page-options': [30, 45, 50] }"
      >
        <!-- id -->
        <template #[`item.paymentId`]="{ item }">
          {{ splitPaymentId(item.paymentId) }}
        </template>
        <!-- status -->
        <template #[`item.status`]="{ item }">
          <v-chip
            small
            :color="resolvePaymentEventStatusVariant(item.status)"
            :class="`${resolvePaymentEventStatusVariant(item.status)}--text`"
            class="v-chip-light-bg font-weight-semibold text-capitalize"
          >
            {{ resolvePaymentEventStatusName(item.status) }}
          </v-chip>
        </template>
        <template #[`item.service`]="{ item }">
          <v-img
            v-if="item.service"
            :src="require(`@/assets/images/svg/${item.service}-${$vuetify.theme.dark ? 'dark' : 'light'}.svg`)"
            max-width="40px"
          ></v-img>
        </template>
        <template #[`item.merchant`]="{ item }">
          <div>{{ item.website.name }} ({{ item.website.company.accountName }})</div>
        </template>
        <template #[`item.card`]="{ item }">
          <div class="d-flex" v-if="item.cardType" style="text-align: center">
            <v-img
              :src="require(`@/assets/images/svg/${item.cardType}-${$vuetify.theme.dark ? 'dark' : 'light'}.svg`)"
              max-width="50px"
              contain
              class="me-2"
            ></v-img>
            <div>
              <div>{{item.cardNumber}}</div>
              <div>{{item.cardHolderName}}</div>
            </div>
          </div>

        </template>
        <template #[`item.amount`]="{ item }">
          <div>{{ item.amount }} {{ item.currency }}</div>
        </template>
        <template #[`item.refundedAmount`]="{ item }">
          <div v-if="item.refundedAmount">{{ item.refundedAmount }} {{ item.currency }}</div>
          <span v-else>-</span>
        </template>
        <template #[`item.createdAt`]="{ item }">
          <div style="width: 90px">{{ item.createdAt | moment(`${getDateFormat(item.createdAt)}`) }}</div>
        </template>

        <!-- actions -->
        <template #[`item.actions`]="{ item }">
          <v-menu :ref="`actionsMenuOpened${item.id}`" bottom left>
            <template #activator="{ on, attrs }">
              <v-btn class="payments-list-item-show-more-actions" icon v-bind="attrs" v-on="on">
                <v-icon>{{ icons.mdiDotsVertical }}</v-icon>
              </v-btn>
            </template>

            <v-list>
              <v-list-item class="payments-list-item-details" :to="{ name: 'payment-event-view', params: { id: item.id } }">
                <v-list-item-title>
                  <v-icon size="20" class="me-2">
                    {{ icons.mdiFileDocumentOutline }}
                  </v-icon>
                  <span>Details</span>
                </v-list-item-title>
              </v-list-item>
              <v-list-item v-if="[paymentEventStatusEnum.COMPLETED, paymentEventStatusEnum.PARTIALLY_REFUNDED].includes(item.status)">
                <v-dialog
                  v-model="refundDialog"
                  persistent
                  max-width="350"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-list-item-title
                      v-bind="attrs"
                      v-on="on"
                      class="payments-list-item-refund"
                      @click="calculateRefundAmount(item)"
                    >
                      <v-icon size="20" class="me-2">
                        {{ icons.mdiCashRefund }}
                      </v-icon>
                      <span>
                        Refund
                      </span>
                    </v-list-item-title>
                  </template>
                  <v-card>
                    <v-form ref="form" v-model="valid" class="multi-col-validation" @submit.prevent="refundPayment(item.paymentId, refundAmount)">
                      <v-card-title class="text-h5">
                        Refund payment
                      </v-card-title>
                      <v-card-text>Refunds take 5-10 days to appear on a customer's statement. Fees for the original payment won't be returned, but there are no additional fees for the refund.</v-card-text>
                      <v-card-text>
                        <v-text-field
                          :suffix="item.currency"
                          v-model="refundAmount"
                          :rules="[validators.requiredExtended, validators.between(
                            refundAmount,
                            0.01,
                            item.refundedAmount ? Number(item.amount) - Number(item.refundedAmount) : Number(item.amount))
                            ]"
                          type="number"
                          label="Refund"
                        >
                        </v-text-field>
                      </v-card-text>
                      <v-card-actions>
                        <v-spacer></v-spacer>
                        <v-btn
                          color="green darken-1"
                          text
                          @click="refundDialog = false"
                        >
                          Cancel
                        </v-btn>
                        <v-btn
                          color="green darken-1"
                          text
                          type="submit"
                          @click="$refs[`actionsMenuOpened${item.id}`].save()"
                        >
                          Refund
                        </v-btn>
                      </v-card-actions>
                    </v-form>
                  </v-card>
                </v-dialog>
              </v-list-item>
            </v-list>
          </v-menu>
        </template>
      </v-data-table>
    </v-card>
  </div>
</template>

<script>
import { mdiCashRefund, mdiDotsVertical, mdiFileDocumentOutline } from '@mdi/js';
import usePaymentEventsList from '@/views/payments/payment-events/usePaymentEventsList';
import Roles from '@/enums/roles.enum';
import { getDateFormat } from '@core/utils/filter';
import { between, requiredExtended } from '@core/utils/validation';
import { ref } from '@vue/composition-api/dist/vue-composition-api.esm';

export default {
  props: ['subscriptionId'],

  setup(props) {
    const {
      status,
      paymentEventStatus,
      paymentEventStatusEnum,
      PaymentEventStatusFilter,
      paymentEventServiceEnum,
      companies,
      paymentEventListTable,
      tableColumns,
      searchQuery,
      statusFilter,
      cardTypeFilter,
      cardTypeOptions,
      paymentEventDateFilter,
      paymentEventDateModal,
      presetsForPaymentEventDatePicker,
      selectedPaymentEventPresetDate,
      loading,
      options,
      totalPaymentEventListTable,
      companyFilter,
      fetchPaymentEvents,
      resolvePaymentEventStatusVariant,
      resolvePaymentEventStatusName,
      splitPaymentId,
      refundPaymentEvent,
      refundDialog,
      refundAmount,
    } = usePaymentEventsList(props);

    const valid = ref(false);
    const form = ref(null);

    const validate = () => {
      form.value.validate();
    };

    const calculateRefundAmount = payment => {
      if (payment.refundedAmount) {
        return refundAmount.value = Number(payment.amount) - Number(payment.refundedAmount);
      }

      return refundAmount.value = Number(payment.amount);
    };

    const refundPayment = (paymentId, amount) => {
        if (valid.value) {
          refundDialog.value = false;
          refundPaymentEvent(paymentId, Number(amount));
        } else {
          validate();
        }
    };

    const paymentEventItemClass = () => {
      return 'payment-event-item';
    };

    return {
      status,
      paymentEventStatus,
      paymentEventStatusEnum,
      PaymentEventStatusFilter,
      paymentEventServiceEnum,
      companies,
      paymentEventListTable,
      tableColumns,
      searchQuery,
      statusFilter,
      companyFilter,
      cardTypeFilter,
      cardTypeOptions,
      paymentEventDateFilter,
      paymentEventDateModal,
      presetsForPaymentEventDatePicker,
      totalPaymentEventListTable,
      loading,
      options,
      fetchPaymentEvents,
      resolvePaymentEventStatusVariant,
      resolvePaymentEventStatusName,
      splitPaymentId,
      paymentEventItemClass,
      getDateFormat,
      selectedPaymentEventPresetDate,
      refundPaymentEvent,
      calculateRefundAmount,
      refundDialog,
      refundAmount,
      refundPayment,
      validators: { requiredExtended, between },
      form,
      valid,
      icons: {
        mdiFileDocumentOutline,
        mdiDotsVertical,
        mdiCashRefund,
      },
      props,
    };
  },
  computed: {
    isAdmin() {
      return this.$store.state.auth.profile && this.$store.state.auth.profile.role === Roles.ADMIN;
    },
  },
};
</script>

<style lang="scss">
.payment-event-date-picker {
  > .v-card__actions {
    display: block !important;
    > div.row:last-child {
      text-align: end;
    }
  }
}
.payment-event-date-filter,
.payment-event-company-filter {
  > .v-input__control > .v-input__slot {
    background: transparent !important;
  }
  .v-label {
    font-size: 14px;
    color: rgba(94, 86, 105, 0.38);
  }
}
@import '@core/preset/preset/apps/user.scss';
</style>
