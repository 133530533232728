import store from '@/store';
import { ref, watch } from '@vue/composition-api';
import Vue from 'vue';
import { isAdmin } from '@/services/jwt.service';
import {
  PaymentEventStatus,
  PaymentEventStatusEnum,
  PaymentEventStatusFilter
} from '@/enums/payments/payment-event-status.enum';
import CardType from '@/enums/card-type.enum';
import moment from 'moment';
import { PaymentProviderEnum } from '@/enums/payments/payment-provider.enum';

export default function usePaymentEventsList(props, toFetchCompanies = true) {
  const paymentEventListTable = ref([]);

  const tableColumns = [
    { text: 'ID', value: 'paymentId', sortable: false },
    { text: 'Merchant', value: 'merchant', sortable: false },
    { text: 'Amount', value: 'amount', sortable: false },
    { text: 'Refunded', value: 'refundedAmount', sortable: false },
    { text: 'Service', value: 'service', sortable: false },
    { text: 'Card', value: 'card', sortable: false, align: 'center' },
    { text: 'Status', value: 'status', sortable: false },
    { text: 'Created At', value: 'createdAt', sortable: false },
    {
      text: 'Actions',
      value: 'actions',
      align: 'center',
      sortable: false,
    },
  ];

  const refundDialog = ref(false);
  const refundAmount = ref(null);
  const searchQuery = ref('');
  const statusFilter = ref(null);
  const status = ref(null);
  const companyFilter = ref(null);
  const cardTypeFilter = ref(null);
  const paymentEventDateFilter = ref(null);
  const paymentEventDateModal = ref(null);
  const companies = ref([]);
  const totalPaymentEventListTable = ref(0);
  const loading = ref(false);
  const options = ref({
    sortBy: ['createdAt'],
    sortDesc: [true],
  });

  const newDate = new Date();
  const presetsForPaymentEventDatePicker = [
    {
      title: '3 Days',
      value: [moment(newDate).subtract(2, 'days').format('YYYY-MM-DD'), moment(newDate).format('YYYY-MM-DD')],
    },
    {
      title: '7 Days',
      value: [moment(newDate).subtract(6, 'days').format('YYYY-MM-DD'), moment(newDate).format('YYYY-MM-DD')],
    },
    {
      title: '30 Days',
      value: [moment(new Date()).subtract(29, 'days').format('YYYY-MM-DD'), moment(newDate).format('YYYY-MM-DD')],
    },
    {
      title: 'This Month',
      value: [moment(newDate).startOf('month').format('YYYY-MM-DD'), moment(newDate).format('YYYY-MM-DD')],
    },
    {
      title: 'Last Month',
      value: [
        moment(newDate).subtract(1, 'month').startOf('month').format('YYYY-MM-DD'),
        moment(newDate).subtract(1, 'month').endOf('month').format('YYYY-MM-DD'),
      ],
    },
    {
      title: 'Last 3 Months',
      value: [
        moment(newDate).subtract(3, 'month').startOf('month').format('YYYY-MM-DD'),
        moment(newDate).subtract(1, 'month').endOf('month').format('YYYY-MM-DD'),
      ],
    },
  ];

  const paymentEventStatus = PaymentEventStatus;
  const paymentEventStatusEnum = PaymentEventStatusEnum;
  const paymentEventServiceEnum = PaymentProviderEnum;

  const resolvePaymentEventStatusVariant = paymentEventStatus => {
    switch (paymentEventStatus) {
      case PaymentEventStatusEnum.COMPLETED:
      case PaymentEventStatusEnum.REFUNDED:
      case PaymentEventStatusEnum.PARTIALLY_REFUNDED:
        return 'success';
      case PaymentEventStatusEnum.FAILED:
      case PaymentEventStatusEnum.EXPIRED:
        return 'danger';
      default:
        return 'warning';
    }
  };

  const resolvePaymentEventStatusName = paymentEventStatus => {
    return PaymentEventStatus.find(status => status.value === paymentEventStatus).title;
  };

  // fetch payments
  const fetchPaymentEvents = () => {
    store
      .dispatch('paymentEvent/fetchPaymentEvents', {
        q: searchQuery.value,
        cardType: cardTypeFilter.value,
        paymentEventDate: paymentEventDateFilter.value,
        status: statusFilter.value,
        companyId: companyFilter.value,
        sortBy: options.value.sortBy[0],
        sortDesc: options.value.sortDesc[0],
        page: options.value.page,
        itemsPerPage: options.value.itemsPerPage,
        subscriptionId: props.subscriptionId,
      })
      .then(response => {
        paymentEventListTable.value = response.data.data;
        totalPaymentEventListTable.value = response.data.totalCount;
        loading.value = false;
      })
      .catch(() => {
        Vue.notify({
          type: 'error',
          title: 'Payments',
          text: 'Cannot fetch payments',
        });
      });
  };

  // refund payment
  const refundPaymentEvent = (paymentId, amount) => {
    store
      .dispatch('paymentEvent/refundPaymentEventByPaymentId', { paymentId, amount })
      .then(() => {
        fetchPaymentEvents();
        Vue.notify({
          type: 'success',
          title: 'Payments',
          text: 'Refund operation was successful',
        });
      })
      .catch(() => {
        Vue.notify({
          type: 'error',
          title: 'Payments',
          text: `Cannot refund payment with id: ${paymentId}`,
        });
      });
  };

  // fetch companies
  const fetchCompanies = () => {
    store
      .dispatch('company/fetchCompanies')
      .then(response => {
        companies.value = response.data;
      })
      .catch(() => {
        Vue.notify({
          type: 'error',
          title: 'Payments',
          text: 'Cannot fetch companies',
        });
      });
  };

  watch([options], () => {
    loading.value = true;
    fetchPaymentEvents();
  });

  watch([searchQuery, statusFilter, companyFilter, cardTypeFilter, paymentEventDateFilter], () => {
    options.value.page = 1;
    loading.value = true;
    fetchPaymentEvents();
  });

  if (toFetchCompanies && isAdmin()) {
    fetchCompanies();
  }

  const selectedPaymentEventPresetDate = preset => {
    return (paymentEventDateFilter.value === preset);
  };

  const splitPaymentId = paymentId => paymentId.split('-').shift();

  const cardTypeOptions = CardType;

  return {
    status,
    paymentEventListTable,
    tableColumns,
    searchQuery,
    statusFilter,
    companyFilter,
    cardTypeFilter,
    cardTypeOptions,
    paymentEventDateFilter,
    paymentEventDateModal,
    presetsForPaymentEventDatePicker,
    companies,
    totalPaymentEventListTable,
    loading,
    options,
    paymentEventStatus,
    paymentEventStatusEnum,
    PaymentEventStatusFilter,
    resolvePaymentEventStatusVariant,
    resolvePaymentEventStatusName,
    splitPaymentId,
    fetchPaymentEvents,
    selectedPaymentEventPresetDate,
    refundPaymentEvent,
    paymentEventServiceEnum,
    refundDialog,
    refundAmount,
  };
}
